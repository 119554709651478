import useDictionarySelectionSearch from "hooks/useDictionarySelectionSearch";
import React from "react";
import { createPortal } from "react-dom";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
interface IDictionaryPopoverPortalProps {
  children: any;
  className?: string;
  element?: string;
  open: boolean;
}
function DictionaryPopoverPortal({
  children,
  className = "dictionary-portal",
  element = "div",
  open
}: IDictionaryPopoverPortalProps) {
  const [container] = React.useState(() => {
    const el = document.createElement(element);
    el.classList.add(className);
    return el;
  });
  React.useEffect(() => {
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  }, [container]);
  if (!open) return null;
  return createPortal(children, container);
}

/**
 * Looks up selection and display results in a tolltip
 * @returns
 */
export default function DictionaryPopover() {
  const {
    rect,
    definition
  } = useDictionarySelectionSearch();
  const myRef = React.useRef<HTMLDivElement | null>(null);
  const open = Boolean(definition?.description) && Boolean(definition?.words);
  if (!rect) return null;
  return <DictionaryPopoverPortal open={open} data-sentry-element="DictionaryPopoverPortal" data-sentry-component="DictionaryPopover" data-sentry-source-file="DictionaryPopover.tsx">
            <StyledPaper elevation={3} ref={myRef} left={`${rect.x}px`} top={`${rect.y + window.pageYOffset + rect.height}px`} data-sentry-element="StyledPaper" data-sentry-source-file="DictionaryPopover.tsx">
                <Typography variant="overline" data-sentry-element="Typography" data-sentry-source-file="DictionaryPopover.tsx">
                    {definition?.words.join(", ")}
                </Typography>
                <Typography variant="body2" data-sentry-element="Typography" data-sentry-source-file="DictionaryPopover.tsx">
                    {definition?.description}
                </Typography>
            </StyledPaper>
        </DictionaryPopoverPortal>;
}
const StyledPaper = styled(Paper)<{
  left: string;
  top: string;
}>(({
  left,
  theme,
  top
}) => ({
  backgroundColor: "white",
  padding: theme.spacing(1, 2, 2, 2),
  position: "absolute",
  left,
  top,
  maxWidth: "580px",
  zIndex: theme.zIndex.fab
}));